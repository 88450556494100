import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import { CloneAndSetupWorkflowBodySchemaRequest } from '@/validations/CloneAndSetupWorkflowRequestValidation';
import { CloneAndSetupWorkflowResponseSchema } from '@/validations/CloneAndSetupWorkflowResponseValidation';
import { CloneWorkflowBodySchemaRequest } from '@/validations/CloneWorkflowRequestValidation';
import { CloneWorkflowResponseSchema } from '@/validations/CloneWorkflowResponseValidation';
import { receiveMessageSchema } from '@/validations/CreateBotMessageValidation';
import { createChatSessionBodySchema } from '@/validations/CreateChatSessionRequestValidation';
import { createMessageBodySchema } from '@/validations/CreateMessageRequestValidation';
import { createMessageResponseSchema } from '@/validations/CreateMessageResponseValidation';
import { getMessagesBodySchema } from '@/validations/GetMessagesRequestValidation';
import { assignAccessBodySchema } from '@/validations/assignAccessBodySchema';
import {
  BotBodySchemaRequest,
  BotBodySchemaResponse,
} from '@/validations/botBodySchemaValidation';
import { chatSessionSchema } from '@/validations/chatSessionBodySchemaValidation';
import { chatSessionResponseSchema } from '@/validations/chatSessionResponseSchemaValidation';
import { checkBotNameBodySchema } from '@/validations/checkBotNameBodySchema';
import { checkBotNameResponseSchema } from '@/validations/checkBotNameResponseSchema';
import { checkChatSessionNameBodySchema } from '@/validations/checkChatSessionNameBodySchema';
import { checkChatSessionNameResponseSchema } from '@/validations/checkChatSessionNameResponseSchema';
import { createUserBodyRequestSchema } from '@/validations/createUserBodyRequestSchema';
import { createUserResponseSchema } from '@/validations/createUserResponseSchema';
import { deleteBotBodySchemaRequest } from '@/validations/deleteBotBodySchemaRequest';
import { deleteWorkspaceBodySchemaRequest } from '@/validations/deleteWorkspaceRequest';
import { freeTrialInviteBodySchema } from '@/validations/freeTrialInviteBodySchema';
import { freeTrialInviteResponseSchema } from '@/validations/freeTrialInviteResponseSchema';
import { frontendConfigurationsSchema } from '@/validations/frontendConfigurationsSchema';
import { getAllChatSessionsBodySchema } from '@/validations/getAllChatSessionsBodySchema';
import { getAllWorkflowsByWorkspaceIdRequestSchema } from '@/validations/getAllWorkflowsByWorkspaceRequest';
import { getBotsByWorkspaceIdOrWorkflowIdandUserIdRequest } from '@/validations/getBotsByWorkspaceIdOrWorkflowIdandUserIdSchema';
import { inviteUsersToPlatformResponseSchema } from '@/validations/inviteUsersToPlatformResponseSchema';
import { inviteUsersToPlatfromBodySchema } from '@/validations/inviteUsersToPlatfromBodySchema';
import { messageSchema } from '@/validations/messageSchemaValidation';
import {
  AddDocumentsAdvancedWorkflowSchemaRequest,
  RemoveDocumentsAdvancedWorkflowSchemaRequest,
  SetupAdvancedWorkflowResponseSchemaRequest,
  SetupAdvancedWorkflowSchemaRequest,
} from '@/validations/setupAdvancedWorkflowValidation';
import { shareAccessBodySchema } from '@/validations/shareAccessBodySchema';
import { shareAccessResponseSchema } from '@/validations/shareAccessResponseSchema';
import {
  toggleFreeWorklfowsInMarketplaceBodySchemaResponse,
  toggleFreeWorklfowsInMarketplaceSchemaResponse,
} from '@/validations/toggleFreeWorklfowsInMarketplaceBodySchemaResponse';
import { UpdateBotBodySchemaRequest } from '@/validations/updateBotBodySchema';
import { updateChatSessionBodySchema } from '@/validations/updateChatSessionBodySchema';
import { updateChatSessionResponseSchema } from '@/validations/updateChatSessionResponseSchema';
import { UpdateWorkspaceBodySchemaRequest } from '@/validations/updateWorkspaceBodySchema';
import { userPermissionsResponseSchema } from '@/validations/userPermissionsResponseSchema';
import {
  WorkflowBodySchemaResponse,
  workflowSchemaResponse,
} from '@/validations/workflowBodySchemaResponse';
import { workflowBotsObjectResponseSchema } from '@/validations/workflowBotsResponseSchema';
import {
  WorkflowBodySchemaRequest,
  WorkflowBodySchemaUpdateRequest,
  WorkflowNamesSchema,
} from '@/validations/worksflowRequestBodySchemaValidation';
import {
  WorkspaceBodySchemaResponse,
  workspaceSchemaResponse,
} from '@/validations/workspaceBodySchemaResponse';
import { WorkspaceBodySchemaRequest } from '@/validations/workspaceRequestBodySchemaValidation';

const c = initContract();

const BotSchemaResponse = z.object({
  bot: BotBodySchemaResponse,
});
const chatSessionsRequest = createChatSessionBodySchema;

const chatSessionsResponse = z.object({
  chat_session: chatSessionResponseSchema,
});

const createMessageRequest = createMessageBodySchema;

export const createMessageResponse = z.object({
  message: createMessageResponseSchema,
});

export const getMessagesResponse = z.object({
  chat_session_id: chatSessionSchema,
  messages: z.array(messageSchema),
  sessionData: chatSessionResponseSchema,
});

const getAllChatSessionsRequest = getAllChatSessionsBodySchema;

const getAllChatSessionsResponse = z.object({
  bot_id: z.string(),
  chat_sessions: z.array(chatSessionResponseSchema),
});
const updateChatSessionResponse = z.object({
  chat_session: updateChatSessionResponseSchema,
});

const deleteChatSessionResponse = z.object({
  deleted_chat_session: chatSessionResponseSchema,
});
const getAllWorkflowsByWorkspaceIdRequest =
  getAllWorkflowsByWorkspaceIdRequestSchema;

const getAllWorkflowsResponse = workflowBotsObjectResponseSchema;
const orderDirection = z.enum(['asc', 'desc']);
const paginationObject = z.object({
  limit: z.number().optional(),
  page: z.number().optional(),
  orderBy: z.string().optional().default('created_at'),
  orderDirection: orderDirection.optional(),
});

const paginationObjectResponse = z.object({
  currentPage: z.number(),
  totalPages: z.number(),
  totalObjects: z.number(),
});

const getAllBotsQueryObject = z
  .object({
    userId: z.string().optional(),
    workspaceId: z.string().optional(),
    workflowId: z.string().optional(),
  })
  .merge(paginationObject);
const WorkspaceResponseSchema = WorkspaceBodySchemaResponse;

const receiveMessageResonseRequest = receiveMessageSchema;

const cloneWorkflowRequest = CloneWorkflowBodySchemaRequest;

const cloneWorkflowResponse = CloneWorkflowResponseSchema;

export const validationContract = c.router({
  Access: {
    assignAccess: {
      method: 'POST',
      path: '/updateAuthz',
      responses: {
        201: z.object({
          tuple: z.string(),
        }),
      },
      body: assignAccessBodySchema,
      summary: 'Assign access',
    },
    deleteAccess: {
      method: 'DELETE',
      path: '/updateAuthz',
      responses: {
        201: z.object({
          tuple: z.string(),
        }),
      },
      body: assignAccessBodySchema,
      summary: 'Delete access',
    },
  },
  Users: {
    shareAccess: {
      method: 'POST',
      path: '/users/share-access',
      responses: {
        201: shareAccessResponseSchema,
      },
      body: shareAccessBodySchema,
      summary: 'Share access of object with users',
    },

    createUser: {
      method: 'POST',
      path: '/users/create-user',
      responses: {
        201: z.object({
          createUserResponseSchema,
        }),
      },
      body: createUserBodyRequestSchema,
      summary: 'Create a User',
    },
    freeTrialInvite: {
      method: 'POST',
      path: '/users/free-trial-invite',
      responses: {
        201: z.object({
          freeTrialInviteResponseSchema,
        }),
      },
      body: freeTrialInviteBodySchema,
      summary: 'Invite users to the platform for a free trial',
    },
    platformInvite: {
      method: 'POST',
      path: '/users/invite-users',
      responses: {
        201: z.object({
          inviteUsersToPlatformResponseSchema,
        }),
      },
      body: inviteUsersToPlatfromBodySchema,
      summary: 'Invite users to the platform',
    },
    userPermssions: {
      method: 'GET',
      path: '/users/permissions',
      responses: {
        200: userPermissionsResponseSchema,
      },
      summary: 'Get user permissions',
    },
  },
  configurations: {
    feConfigurations: {
      method: 'GET',
      path: 'configurations/feconfigurations',
      responses: {
        200: frontendConfigurationsSchema,
      },
      summary: 'Get frontend configurations',
    },
  },
  Bots: {
    createBot: {
      method: 'POST',
      path: '/bots',
      responses: {
        201: BotSchemaResponse,
      },
      body: BotBodySchemaRequest,
      summary: 'Create a bot',
    },
    getAllBots: {
      method: 'GET',
      path: '/bots',
      query: paginationObject,
      responses: {
        200: z.object({
          bots: z.array(BotBodySchemaResponse),
          pageInfo: paginationObjectResponse,
        }),
      },
      summary: 'Get all bots',
    },
    getBot: {
      method: 'GET',
      path: '/bots/:bot_id',
      responses: {
        200: BotSchemaResponse,
      },
      summary: 'Get a bot',
    },
    getBotByWorkspaceId: {
      method: 'POST',
      path: '/bots/getAll',
      query: paginationObject,
      responses: {
        200: z.object({
          bots: z.array(BotBodySchemaResponse),
          pageInfo: paginationObjectResponse,
        }),
      },
      body: getBotsByWorkspaceIdOrWorkflowIdandUserIdRequest,
      summary: 'Get all bots by user_id and workspace_id or workflow_id',
    },
    getBotsByWorkspaceIdOrWorkflowId: {
      method: 'GET',
      path: '/bots/getAll',
      query: getAllBotsQueryObject,
      responses: {
        200: z.object({
          bots: z.array(BotBodySchemaResponse),
          pageInfo: paginationObjectResponse,
        }),
      },
      summary: 'Get all bots by user_id and workspace_id or workflow_id',
    },
    updateBot: {
      method: 'PUT',
      path: '/bots',
      responses: {
        200: BotSchemaResponse,
      },
      body: UpdateBotBodySchemaRequest,
      summary: 'Update a bot',
    },
    checkBotName: {
      method: 'POST',
      path: '/bots/check-bot-name',
      responses: {
        200: checkBotNameResponseSchema,
      },
      body: checkBotNameBodySchema,
      summary: 'Check if bot name is unique before creating the bot',
    },
    deleteBot: {
      method: 'DELETE',
      path: '/bots',
      responses: {
        200: BotSchemaResponse,
      },
      body: deleteBotBodySchemaRequest,
      summary: 'Delete a bot',
    },
  },
  Workspaces: {
    createWorkspace: {
      method: 'POST',
      path: '/workspaces',
      responses: {
        201: WorkspaceResponseSchema,
      },
      body: WorkspaceBodySchemaRequest,
      summary: 'Create a workspace',
    },
    getWorkspacesByUserId: {
      method: 'POST',
      path: '/workspaces/getAll',
      query: paginationObject,
      responses: {
        200: z.object({
          workspaces: z.array(workspaceSchemaResponse),
          pageInfo: paginationObjectResponse,
        }),
      },
      body: z.object({}),
      summary: 'Get all workspaces by user_id',
    },
    getWorkspacesByUserIdGetRequest: {
      method: 'GET',
      path: '/workspaces/getAll',
      responses: {
        200: z.object({
          workspaces: z.array(workspaceSchemaResponse),
          pageInfo: paginationObjectResponse,
        }),
      },
      query: z.object({
        userId: z.string().optional(),
        limit: z.number().optional(),
        page: z.number().optional(),
        orderBy: z.string().optional().default('created_at'),
        orderDirection: orderDirection.optional(),
      }),
      summary: 'Get all workspaces by user_id',
    },
    getWorkspace: {
      method: 'GET',
      path: '/workspaces/:workspace_id',
      responses: {
        200: WorkspaceBodySchemaResponse,
      },
      summary: 'Get a Workspace by ID',
    },
    deleteWorkspace: {
      method: 'DELETE',
      path: '/workspaces',
      responses: {
        200: WorkspaceBodySchemaResponse,
      },
      body: deleteWorkspaceBodySchemaRequest,
      summary: 'Delete a Workspace by ID',
    },
    updateWorkspace: {
      method: 'PUT',
      path: '/workspaces',
      responses: {
        200: WorkspaceBodySchemaResponse,
      },
      body: UpdateWorkspaceBodySchemaRequest,
      summary: 'Update a Workspace',
    },
  },
  Workflows: {
    createWorkflow: {
      method: 'POST',
      path: '/workflows',
      responses: {
        201: WorkflowBodySchemaResponse,
      },
      body: WorkflowBodySchemaRequest,
    },
    getAllWorkflowsByWorkspace: {
      method: 'POST',
      path: '/workflows/get-workspace-workflows',
      query: paginationObject,
      responses: {
        200: z.object({
          workflows: z.array(getAllWorkflowsResponse),
          pageInfo: paginationObjectResponse,
        }),
      },
      body: getAllWorkflowsByWorkspaceIdRequest,
    },
    toggleFreeWorklfowsInMarketplace: {
      method: 'PUT',
      path: '/workflows/toggle-free-marketplace',
      responses: {
        200: toggleFreeWorklfowsInMarketplaceSchemaResponse,
      },
      body: toggleFreeWorklfowsInMarketplaceBodySchemaResponse,
    },
    getAllWorkflowsInMarketplace: {
      method: 'GET',
      path: '/workflows/get-marketplace-workflows',
      query: paginationObject,
      responses: {
        200: z.object({
          workflows: z.array(getAllWorkflowsResponse),
          pageInfo: paginationObjectResponse,
        }),
      },
    },
    getAllPublishedWorkflows: {
      method: 'GET',
      path: '/workflows/get-published-workflows',
      query: paginationObject,
      responses: {
        200: z.object({
          workflows: z.array(workflowSchemaResponse),
          pageInfo: paginationObjectResponse,
        }),
      },
    },
    getWorkflow: {
      method: 'GET',
      path: '/workflows/:workflow_id',
      responses: {
        200: WorkflowBodySchemaResponse,
      },
      summary: 'Get a Workflow by ID',
    },
    updateWorkflow: {
      method: 'PUT',
      path: '/workflows',
      responses: {
        201: WorkflowBodySchemaResponse,
      },
      body: WorkflowBodySchemaUpdateRequest,
    },
    cloneWorkflow: {
      method: 'POST',
      path: '/workflows/clone-workflow',
      responses: {
        201: cloneWorkflowResponse,
      },
      body: cloneWorkflowRequest,
    },
    cloneAndSetupWorkflow: {
      method: 'POST',
      path: '/workflows/clone-and-setup',
      responses: {
        201: CloneAndSetupWorkflowResponseSchema,
      },
      body: CloneAndSetupWorkflowBodySchemaRequest,
    },
    createAdvancedWorkflow: {
      method: 'POST',
      path: '/workflows/advanced-workflow',
      responses: {
        201: WorkflowBodySchemaResponse,
      },
      body: WorkflowNamesSchema,
    },
    setupAdvancedWorkflow: {
      method: 'POST',
      path: '/workflows/advanced-workflow/setup',
      responses: {
        200: z.object({}),
      },
      body: SetupAdvancedWorkflowSchemaRequest,
    },
    setupAdvancedWorkflowResponse: {
      method: 'PUT',
      path: '/workflows/advanced-workflow/setup',
      responses: {
        200: WorkflowBodySchemaResponse,
      },
      body: SetupAdvancedWorkflowResponseSchemaRequest,
    },
    addDocumentsAdvancedWorkflow: {
      method: 'POST',
      path: '/workflows/advanced-workflow/documents',
      responses: {
        200: WorkflowBodySchemaResponse,
      },
      body: AddDocumentsAdvancedWorkflowSchemaRequest,
    },
    removeDocumentsAdvancedWorkflow: {
      method: 'POST',
      path: '/workflows/advanced-workflow/documents/remove',
      responses: {
        200: WorkflowBodySchemaResponse,
      },
      body: RemoveDocumentsAdvancedWorkflowSchemaRequest,
    },
  },
  botInteractions: {
    createChatSession: {
      method: 'POST',
      path: '/bots/chat-sessions',
      responses: {
        201: chatSessionsResponse,
      },
      body: chatSessionsRequest,
      summary: 'Create a chat session',
    },
    updateChatSession: {
      method: 'PUT',
      path: '/bots/chat-sessions',
      responses: {
        200: updateChatSessionResponse,
      },
      body: updateChatSessionBodySchema,
      summary: 'Update a chat session with chat session name',
    },
    createMessage: {
      method: 'POST',
      path: '/bots/chat-sessions/create-message',
      responses: {
        201: createMessageResponse,
      },
      body: createMessageRequest,
      summary: 'Create a message in a chat session',
    },
    getMessages: {
      method: 'GET',
      path: '/bots/chat-sessions/:chatSessionId',
      responses: {
        200: getMessagesResponse,
      },
      summary: 'Get chat session messages',
    },
    getChatSessionMessages: {
      method: 'POST',
      path: '/bots/chat-sessions/get-messages',
      responses: {
        200: getMessagesResponse,
      },
      body: getMessagesBodySchema,
      summary: 'Get chat session messages',
    },
    getChatSessions: {
      method: 'POST',
      path: '/bots/chat-sessions/get-all',
      responses: {
        200: getAllChatSessionsResponse,
      },
      body: getAllChatSessionsRequest,
      summary: 'Get all user chat sessions for a bot',
    },
    fetchChatSessions: {
      method: 'GET',
      path: '/bots/chat-sessions/get-all/:type/:id',
      responses: {
        200: getAllChatSessionsResponse,
      },
      pathParams: z.object({
        id: z.string().nonempty('id cannot be empty'),
      }),
      summary: 'Get all user chat sessions for a bot using GET',
    },
    deleteChatSession: {
      method: 'DELETE',
      path: '/bots/chat-sessions/:chatSessionId',
      responses: {
        200: deleteChatSessionResponse,
      },

      pathParams: z.object({
        chatSessionId: z.string(),
      }),
      body: z.object({}),
      summary: 'Delete a chat session',
    },
    receiveMessageResonse: {
      method: 'POST',
      path: '/bots/chat-sessions/receive-message-response',
      responses: {
        200: createMessageResponse,
      },
      body: receiveMessageResonseRequest,
      summary: 'Get all user chat sessions for a bot',
    },
    checkChatSessionName: {
      method: 'POST',
      path: '/bots/chat-sessions/check-chat-session-name',
      responses: {
        200: checkChatSessionNameResponseSchema,
      },
      body: checkChatSessionNameBodySchema,
      summary: 'Check if chat session name is unique before creating the bot',
    },
  },

  uploads: {
    uploads: {
      method: 'GET',
      path: '/uploads',
      responses: {
        200: z.object({
          presignedUrl: z.string(),
          urlType: z.string(),
          accountName: z.string(),
          bucketName: z.string(),
        }),
      },
      query: z.object({
        filePath: z.string().optional(),
        bucket: z.string().optional(),
        flowFilename: z.string().optional(),
      }),
    },
  },
  getDownloadURL: {
    method: 'GET',
    path: '/getDownloadURL',
    responses: {
      200: z.object({
        presignedDownloadUrl: z.string(),
      }),
    },
    query: z.object({
      filelink: z.string().optional(),
    }),
  },
});
