import { z } from 'zod';

export const CloneWorkflowResponseSchema = z.object({
  workflow_id: z.string().nonempty('workflow_id cannot be empty'),
  workflow_name: z.string().nonempty('workflow_name cannot be empty'),
  workflow_display_name: z.string().optional(),
  avatar: z.string().optional(),
  voice: z.string().optional(),
  marketplace: z.boolean().optional(),
  created_at: z.string().optional(),
  updated_at: z.string().optional(),
  created_by: z.string().optional(),
  updated_by: z.string().optional(),
});
